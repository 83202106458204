export default [
  {
    path: '/isp/router-list',
    name: 'isp-router-list',
    component: () => import('@/views/isp/router-manage/ListRouter.vue'),
    meta: {
      pageTitle: 'Router Setting',
      breadcrumb: [
        {
          text: 'Router List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/isp/router-create',
    name: 'isp-router-create',
    component: () => import('@/views/isp/router-manage/CreateRouter.vue'),
    meta: {
      pageTitle: 'Router Setting',
      breadcrumb: [
        {
          text: 'Add Router',
          active: true,
        },
      ],
    },
  },
  {
    path: '/isp/router-edit/:routerId',
    name: 'isp-router-edit',
    component: () => import('@/views/isp/router-manage/UpdateRouter.vue'),
    meta: {
      pageTitle: 'Router Setting',
      breadcrumb: [
        {
          text: 'Edit Router',
          active: true,
        },
      ],
    },
  },
  {
    path: '/isp/package-list',
    name: 'isp-package-list',
    component: () => import('@/views/isp/package-manage/ListPackage.vue'),
    meta: {
      pageTitle: 'Package Setting',
      breadcrumb: [
        {
          text: 'Package List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/isp/package-create',
    name: 'isp-package-create',
    component: () => import('@/views/isp/package-manage/CreatePackage.vue'),
    meta: {
      pageTitle: 'Package Setting',
      breadcrumb: [
        {
          text: 'Add Package',
          active: true,
        },
      ],
    },
  },
  {
    path: '/isp/package-edit/:packageId',
    name: 'isp-package-edit',
    component: () => import('@/views/isp/package-manage/UpdatePackage.vue'),
    meta: {
      pageTitle: 'Package Setting',
      breadcrumb: [
        {
          text: 'Edit Package',
          active: true,
        },
      ],
    },
  },
  {
    path: '/isp/isp-user-name-list',
    name: 'isp-username-list',
    component: () => import('@/views/isp/assign-dail-up/ListCustomerUserName.vue'),
  },
  {
    path: '/isp/area-management',
    name: 'isp-area-management',
    component: () => import('@/views/isp/area/Area.vue'),
  },
  {
    path: '/isp/isp-user-name-create',
    name: 'isp-user-name-create',
    component: () => import('@/views/isp/assign-dail-up/CreateCustomerUserName.vue'),
    meta: {
      pageTitle: 'Assign Dial-Up',
      breadcrumb: [
        {
          text: 'Add Assign Dial-Up',
          active: true,
        },
      ],
    },
  },
  {
    path: '/isp/isp-user-name-create-new',
    name: 'isp-user-name-create1',
    component: () => import('@/views/isp/assign-dail-up/CreateCustomerUserName1.vue'),
    meta: {
      pageTitle: 'Assign Dial-Up',
      breadcrumb: [
        {
          text: 'Add Assign Dial-Up1',
          active: true,
        },
      ],
    },
  },
  {
    path: '/isp/isp-user-name-create-new',
    name: 'isp-user-name-create1',
    component: () => import('@/views/isp/assign-dail-up/CreateCustomerUserName1.vue'),
    meta: {
      pageTitle: 'Assign Dial-Up',
      breadcrumb: [
        {
          text: 'Add Assign Dial-Up1',
          active: true,
        },
      ],
    },
  },
  {
    path: '/isp/isp-user-name-edit/:usernameId',
    name: 'isp-user-name-edit',
    component: () => import('@/views/isp/assign-dail-up/UpdateCustomerUserName.vue'),
    meta: {
      pageTitle: 'Assign Dial-Up',
      breadcrumb: [
        {
          text: 'Edit Assign Dial-Up',
          active: true,
        },
      ],
    },
  },
  {
    path: '/isp/isp-user-name-edit-v2/:id',
    name: 'isp-user-name-edit-v2',
    component: () => import('@/views/isp/assign-dail-up/EditCustomerUserName1.vue'),
    meta: {
      pageTitle: 'Assign Dial-Up',
      breadcrumb: [
        {
          text: 'Edit Assign Dial-Up',
          active: true,
        },
      ],
    },
  },
  {
    path: '/api/get-live-data/:usernameId',
    name: 'isp-user-get-live-data',
    component: () => import('@/views/isp/assign-dail-up/GetUserLiveData.vue'),
    meta: {
      pageTitle: 'Get User Live Data',
      breadcrumb: [
        {
          text: 'Live Data',
          active: true,
        },
      ],
    },
  },
  {
    path: '/api/get-not-billing-users/:routerId',
    name: 'not-in-billing-users',
    component: () => import('@/views/isp/router-manage/NotBillingList.vue'),
    meta: {
      pageTitle: 'Not in MikroBilling',
      breadcrumb: [
        {
          text: 'Below Users are not available in your ISP Panel, So you should add them to your ISP Panel.',
          active: true,
        },
      ],
    },
  },
  {
    path: '/api/get-not-router-users/:routerId',
    name: 'not-in-router-users',
    component: () => import('@/views/isp/router-manage/NotRouterList.vue'),
    meta: {
      pageTitle: 'Not in Router',
      breadcrumb: [
        {
          text: 'Below Users are not available in your MikroTik Router, So you can Remove them from your Panel.',
          active: true,
        },
      ],
    },
  },
  {
    path: '/api/get-offline-users/:routerId',
    name: 'offline-users',
    component: () => import('@/views/isp/router-manage/OfflineUsers.vue'),
    meta: {
      pageTitle: 'Offline Users',
      breadcrumb: [
        {
          text: 'Offline User List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/isp/profile-update',
    name: 'update-profile',
    component: () => import('@/views/isp/profile/users-edit/UsersEdit.vue'),
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Profile Update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/isp/account-setting',
    name: 'account-setting',
    component: () => import('@/views/isp/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Profile Update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/isp/change-password',
    name: 'isp-change-password',
    component: () => import('@/views/isp/profile/change-password.vue'),
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Change Password',
          active: true,
        },
      ],
    },
  },
  {
    path: '/isp/mobile-banking-info-update',
    name: 'mobile-banking-payment-setting',
    component: () => import('@/views/isp/mobile-banking-setting/MobilePaymentEdit.vue'),
    meta: {
      pageTitle: 'Payment',
      breadcrumb: [
        {
          text: 'Mobile Banking',
          active: true,
        },
      ],
    },
  },
  {
    path: '/isp/reseller-condition',
    name: 'reseller-condition',
    component: () => import('@/views/isp/reseller/ResellerCondition.vue'),
    meta: {
      pageTitle: 'Payment',
      breadcrumb: [
        {
          text: 'Mobile Banking',
          active: true,
        },
      ],
    },
  },
  {
    path: '/isp/invoice-list',
    name: 'isp-invoice-list',
    component: () => import('@/views/isp/invoice/invoice-list/InvoiceList.vue'),
    meta: {
      pageTitle: 'Invoice',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },

  {
    path: '/isp/invoice-preview/:invoiceId',
    name: 'isp-invoice-preview',
    component: () => import('@/views/isp/invoice/invoice-preview/InvoicePreview.vue'),
  },
  {
    path: '/admin/new-settlement/:ispId',
    name: 'admin-new-settlement',
    component: () => import('@/views/admin/settlement/newSettlement.vue'),
  },
  {
    path: '/admin/settlement-list',
    name: 'admin-settlement-list',
    component: () => import('@/views/admin/settlement/settlementList.vue'),
  },
  {
    path: '/admin/settlement-details/:settlementId',
    name: 'admin-settlement-details',
    component: () => import('@/views/admin/settlement/settlementDetails.vue'),
  },
  {
    path: '/admin/isp-list/',
    name: 'admin-isp-list',
    component: () => import('@/views/admin/ispInfoList/ispList.vue'),
  },
  {
    path: '/admin/isp-ppp-list/',
    name: 'admin-isp-ppp-list',
    component: () => import('@/views/admin/ispInfoList/ispPppList.vue'),
  },
  {
    path: '/admin/isp-unassign-ppp-list/',
    name: 'admin-isp-unassign-ppp-list',
    component: () => import('@/views/admin/ispInfoList/ispUnassignPppList.vue'),
  },
  {
    path: '/admin/user-list/',
    name: 'admin-user-list',
    component: () => import('@/views/admin/usersManage/users/userList.vue'),
  },
  {
    path: '/admin/role-list',
    name: 'admin-role-list',
    component: () => import('@/views/admin/usersManage/roles/roleList.vue'),
  },
  {
    path: '/admin/role-add',
    name: 'admin-role-add',
    component: () => import('@/views/admin/usersManage/roles/addRole.vue'),
  },
  {
    path: '/admin/role-edit/:roleId',
    name: 'admin-role-edit',
    component: () => import('@/views/admin/usersManage/roles/updateRole.vue'),
  },
  {
    path: '/admin/permission-list/',
    name: 'admin-permission-list',
    component: () => import('@/views/admin/usersManage/permission/permissionList.vue'),
  },
  {
    path: '/isp/settlement-list',
    name: 'isp-settlement-list',
    component: () => import('@/views/isp/settlement/settlementList.vue'),
  },
  {
    path: '/isp/un-settlement-list',
    name: 'isp-un-settlement-list',
    component: () => import('@/views/isp/settlement/unSettlementList.vue'),
  },
  {
    path: '/isp/isp-date-wise-report',
    name: 'isp-date-wise-report',
    component: () => import('@/views/isp/report/DateWiseReport.vue'),
  },
  {
    path: '/isp/isp-area-wise-report',
    name: 'isp-area-wise-report',
    component: () => import('@/views/isp/report/AreaWiseReport.vue'),
  },
  {
    path: '/isp/new-connection-report',
    name: 'isp-new-connection-report',
    component: () => import('@/views/isp/report/NewConnectionReport.vue'),
  },
  {
    path: '/isp/isp-month-wise-report',
    name: 'isp-month-wise-report',
    component: () => import('@/views/isp/report/MonthWiseReport.vue'),
  },
  {
    path: '/isp/isp-duplicate-device',
    name: 'isp-duplicate-device',
    component: () => import('@/views/isp/report/DuplicateDevice.vue'),
  },
  {
    path: '/isp/bulk-notification',
    name: 'isp-bulk-notification',
    component: () => import('@/views/isp/notification/BulkNotification.vue'),
  },
  {
    path: '/isp/isp-new-ticket',
    name: 'isp-new-ticket',
    component: () => import('@/views/isp/ticket/NewTicket.vue'),
  },
  {
    path: '/isp/isp-ticket-list',
    name: 'isp-ticket-list',
    component: () => import('@/views/isp/ticket/TicketList.vue'),
  },
  {
    path: '/isp/isp-department-list',
    name: 'isp-department-list',
    component: () => import('@/views/isp/ticket/DepartmentList.vue'),
  },
  {
    path: '/isp/isp-staff-list',
    name: 'isp-staff-list',
    component: () => import('@/views/isp/ticket/StaffList.vue'),
  },
  {
    path: '/isp/isp-un-invoiced-report',
    name: 'isp-un-invoiced-report',
    component: () => import('@/views/isp/report/UnInvoicedReport.vue'),
  },
  {
    path: '/isp/isp-add-reseller',
    name: 'isp-add-reseller',
    component: () => import('@/views/isp/reseller/AddReseller.vue'),
  },
  {
    path: '/isp/isp-create-reseller',
    name: 'isp-create-reseller',
    component: () => import('@/views/isp/reseller/CreateReseller.vue'),
    meta: {
      pageTitle: 'Create Reseller',
      breadcrumb: [
        {
          text: 'Create Reseller',
          active: true,
        },
      ],
    },
  },

  {
    path: '/isp/isp-view-reseller',
    name: 'isp-view-reseller',
    component: () => import('@/views/isp/reseller/ViewReseller.vue'),
  },
  {
    path: '/admin/isp-settlement-list/',
    name: 'admin-isp-settlement-list',
    component: () => import('@/views/admin/settlement/ispSettlementList.vue'),
  },
  {
    path: '/isp/isp-user-password-change',
    name: 'isp-user-password-change',
    component: () => import('@/views/isp/assign-dail-up/UserPasswordChangeIsp.vue'),
    meta: {
      pageTitle: 'User Change Password',
      breadcrumb: [
        {
          text: 'User Change Password',
          active: true,
        },
      ],
    },
  },
  {
    path: '/isp/service-charge-package-list',
    name: 'service-charge-package-list',
    component: () => import(
      '@/views/isp/pay-service-charge-package/PayServiceChargePackage.vue'
    ),
    meta: {
      pageTitle: 'All Package List',
      breadcrumb: [
        {
          text: 'Service Charge Package',
          active: true,
        },
      ],
    },
  },
  {
    path: '/isp/service-payment-history',
    name: 'service-payment-history',
    component: () => import(
      '@/views/isp/pay-service-charge-package/IspServicePaymentHistory.vue'
    ),
    meta: {
      pageTitle: 'Payment History',
      breadcrumb: [
        {
          text: 'Service Payment History',
          active: true,
        },
      ],
    },
  },
  {
    path: '/isp/isp-reseller-condition',
    name: 'isp-reseller-condition',
    component: () => import('@/views/isp/reseller/ResellerCondition.vue'),
    meta: {
      pageTitle: 'Add Reseller',
      breadcrumb: [
        {
          text: 'Add Reseller',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/isp-service-package-list/',
    name: 'admin-isp-service-package-list',
    component: () => import('@/views/admin/service-package/ServicePackageList.vue'),
    meta: {
      pageTitle: 'Service Package List',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/isp-service-package-add/',
    name: 'admin-isp-service-package-add',
    component: () => import('@/views/admin/service-package/ServicePackageAdd.vue'),
    meta: {
      pageTitle: 'Service Package List',
      breadcrumb: [
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/service-package-edit/:servicePid',
    name: 'admin-service-package-edit',
    component: () => import('@/views/admin/service-package/ServicePackageEdit.vue'),
    meta: {
      pageTitle: 'Service Package List',
      breadcrumb: [
        {
          text: 'Update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/coupon-code-list/',
    name: 'admin-coupon-code-list',
    component: () => import('@/views/admin/coupon-code/CouponCodeList.vue'),
    meta: {
      pageTitle: 'Coupon Code List',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/coupon-code-add/',
    name: 'admin-coupon-code-add',
    component: () => import('@/views/admin/coupon-code/CouponCodeAdd.vue'),
    meta: {
      pageTitle: 'Coupon Code List',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/coupon-code-edit/:ccId',
    name: 'admin-coupon-code-edit',
    component: () => import('@/views/admin/coupon-code/CouponCodeEdit.vue'),
    meta: {
      pageTitle: 'Coupon Code List',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/isp/ticket/:id',
    name: 'TicketDetails',
    component: () => import('@/views/isp/ticket/TicketDetails.vue'), // Adjust this to your actual component
  },
]
